.navBar{
    position: fixed;
    max-width: 90em;
    display: flex;
    align-items: center;
    padding: 2.15em 0;
    background-color: white;
    z-index: 100;
    left: 2em;
    right: 2em;
    margin: 0 auto;
    gap: 13em;
}

.logoLink {
    margin-left: 1em;
}

.mainLogo{
    max-width: 19em;
}

.header-nav{
    display: flex;
    margin: 0 auto;
}

.header-nav-li{
    display: flex;
    list-style: none;
    margin: 0 1.5em;
    height: 2em;
    align-items: center;
}

.navBar ul li a{
    color: #4F5665;
    user-select: none;
    font-weight: 400;
    line-height: 1.15em;
    text-decoration: none;
}