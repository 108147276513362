.laptop-box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4em;
}
.laptop{
    display: flex;
    width: 33.5em; /*-9em*/
    height: 24em;
    flex-shrink: 0;
}

.section-info{
    display: flex;
    position: relative;
    justify-content: center;
    gap: 3em;
    margin-left: 2em;
}

.services{
    position: relative;
    margin-left: 8%;
    /*width: 570px;*/
    margin-top: 1em;
}

.heading-services{
    display: flex;
    position: relative;
    color: #0B132A;
    margin-top: 10%;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px; /* 142.857% */
}

.services .text-services{
    display: flex;
    position: relative;
    color: #4F5665;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    width: 412px;
    margin-top: 24px;
}

.dots-list{
    margin-top: 51px;
    position: relative;
}
.dot{
    margin-top: 15px;
    display: flex;
    position: relative;
}

.dots-text{
    margin-left: 10px;
    color: #4F5665;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 214.286% */
}