.section-about {
    scroll-margin-top: 6em;
}

.about-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.section-about > h3 {
    margin-top: 1em;
    color: #0B132A;
    font-size: 30px;
    line-height: 1.4em;
    font-weight: bold;
    text-align: center;
    padding-bottom: 27px;
    padding-top: 2em;
}

.text-about {
    position: relative;
    max-width: 700px;
    font-size: 20px;
    margin: 0 2em;
}

.text-about > p {
    color: #29272A;
    margin-top: 35px;
    font-size: 24px;
    line-height: 37.5px;
    letter-spacing: 0.5px;
    word-wrap: break-word;
    text-align: justify;
}

.about-logo {
    text-align: center;
    width: 350px;
    height: 280px;
    padding: 0 3em;
}